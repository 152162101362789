.product-page {

    .size-element,
    .color-element {
        &.active {
            border: 2px solid #323232;
        }
    }

    .carousel-indicators [data-bs-target] {
        width: 100px;
        height: 66px;
        opacity: 1;
        border: 2px solid transparent;

        img {
            width: 100px;
            height: 66px;
            object-fit: cover;
        }
    }

    .carousel-indicators {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }

    .carousel-indicators .active {
        border: 2px solid #ce0e0e;
    }

    .product-box {
        .carousel-inner {
            .carousel-item {
                img {
                    height: 590px !important;

                    @media screen and (max-width: 768px) {
                        height: auto !important;
                    }
                }
            }
        }
    }
}