.page-content {
    .banner-bg {
        height: 90vh;

        background-size: cover;
        background-position: center;
        /* Center the background image */
        background-repeat: no-repeat;
        /* Do not repeat the image */
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.6);
            /* Example overlay color */
            z-index: 1;
        }
    }

}